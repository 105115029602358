import React, { lazy, Suspense } from 'react'
import { Router } from '@reach/router'

import Loading from 'components/loading'

/**
 * Public Routes
 */
const Login = lazy(() => import('views/login'))
const ErrorPage = lazy(() => import('views/error'))
const ClientApproval = lazy(() => import('views/clientApproval'))
const AddendumAcepted = lazy(() => import('views/addendums/accepted'))
const AddendumRejected = lazy(() => import('views/addendums/rejected'))

/**
 * Private pages, do require an active session
 */
const App = lazy(() => import('views'))

const Routes = () => (
  <Suspense fallback={<Loading />}>
    <Router>
      <Login path='/login' />
      <ClientApproval path='/aceptar-liquidacion/:uid' />
      <AddendumAcepted path='/aceptar-addendums/:uid' />
      <AddendumRejected path='/rechazar-addendums/:uid' />
      <ErrorPage path='/error' />
      <App path='/*' />
    </Router>
  </Suspense>
)

export default Routes
